import styled from 'styled-components';

export const Spacer = ({ x, y, ...restProps }: BoxProps) => {
  return <Box x={x} y={y} {...restProps} />;
};

const Box = styled.div<BoxProps>`
  height: ${({ y }) => (y ? `${y}px` : '32px')};
  width: ${({ x }) => (x ? `${x}px` : '32px')};
`;

export interface BoxProps {
  x?: number;
  y?: number;
}
